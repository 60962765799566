/***** LOGIN STYLE MODAL *****/
.login-form {
	input::placeholder {
		line-height: 1.7em
	}
}

.login-social {
	.facebook,
	.google,
	.twitter {
		width: 40px;
		height: 36px;
	}

	.facebook {
		background: url("/images/social-icon.png") center left no-repeat;
		background-size: 300%;
	}

	.google {
		background: url("/images/social-icon.png") center no-repeat;
		background-size: 300%;
	}

	.twitter {
		background: url("/images/social-icon.png") center right no-repeat;
		background-size: 300%;
	}
}
/*** ACCOUNT POPUP SUCCESS ***/
.muchsuccess {
	padding-top: 200px;
}

.successburst {
	color: #ffffff;
	width: 181px;
	height: 181px;
	text-align: left;
	@extend .horizontal-align-ab;
	top: 0;
	opacity: 0;
	-webkit-animation: myship 1s;
	-webkit-animation-fill-mode: forwards;
}

.successburst:before {
	font-family: 'primo', 'Helvetica', 'sans-serif';
	position: absolute;
	width: 100%;
	height: 100%;
	text-align: center;
	line-height: 181px;
	z-index: 1;
	color: $blueReg;
	font-size: 181px;
	content: "\e01a";
	-webkit-animation: spinX 1s;
}
@-webkit-keyframes spinX {
	0% {
		-webkit-transform: rotate(90deg);
		-webkit-transform-origin: 50%;
	}

	100% {
		-webkit-transform: rotate(0deg);
		-webkit-transform-origin: 50%;
	}
}
@-webkit-keyframes myship {
	from {
		top: 40px;
		opacity: 0;
	}

	to {
		top: 20px;
		opacity: 1;
	}
}

.sucesscheck {
	width: 181px;
	height: 181px;
}

.sucesscheck:before {
	font-family: 'primo', 'Helvetica', 'sans-serif';
	position: absolute;
	width: 181px;
	height: 181px;
	text-align: center;
	line-height: 181px;
	z-index: 1;
	color: #ffffff;
	font-size: 90px;
	content: "\e00d";
}

.loggedin {
	width: 10px;
	height: 10px;
	background: lightgreen;
	right: 1em;
	bottom: 1em;
}
